import B3Storage from './B3Storage'
import Alert from './Alert'
import * as api from '../api'

const getAndStoreBCToken = async () => {
  try {
    const bcToken = await api.getBCToken()

    B3Storage.BCToken.setValue(bcToken || '')
  } catch (error) {
    Alert.error(error.message)
  }
}

export default getAndStoreBCToken
