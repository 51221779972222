import Clipboard from 'clipboard'
import { isEmpty } from 'lodash'

import BasePage from '../../common/BasePage'

// import buttonGroup from './button-group.html'
import quoteDetail from './quoteDetail.html'
import trackingHistory from './trackingHistory.html'
import sendMessage from './sendMessage.html'
import attachFile from './attachFile.html'
import additionalInfo from './additionalInfo.html'
import header from './header.html'
import * as utils from '../../common/utils'

export default class RfqQuote extends BasePage {
  name = 'RfqQuote';

  state = {
    subtotal: 0,
    discount: 0,
    discountType: 0,
    grandTotal: 0,
    shippingTotal: 0,
    taxTotal: 0,
    totalAmount: 0,
    storeInfo: {
      storeName: '',
      storeAddress: '',
      storeCountry: '',
    },
    quoteNumber: '',
    referenceNumber: '',
    createdAt: '',
    expiredAt: '',
    salesRepInfo: {
      salesRepName: '',
    },
    contactInfo: {
      name: '',
      email: '',
      companyName: '',
    },
    shippingAddress: {
      address: '',
      country: '',
    },
    productList: {
      productName: '',
      imageUrl: '',
      quantity: '',
      basePrice: '',
      offeredPrice: '',
    },
    currency: {
      token: '',
      location: '',
    },
    trackingHistory,
    status: -1,
    isCopied: false,
    legalTerms: '',
    notes: '',
    shippingMethod: {
      description: '',
    },
    storefrontAttachFiles: [],
    backendAttachFiles: [],
    hidePriceFromGuests: false,
  };

  statusMap = {
    1: this.text['quotes.list.status.open'],
    4: this.text['quotes.list.status.ordered'],
    5: this.text['quotes.list.status.expired'],
  };

  tpls = {
    quoteDetail,
    trackingHistory,
    sendMessage,
    attachFile,
    additionalInfo,
    header,
  };

  get quoteId() {
    const { urlHelper } = this.utils

    return urlHelper.searchParams.get('id')
  }

  get quoteDate() {
    const { urlHelper } = this.utils

    return urlHelper.searchParams.get('date')
  }

  get quoteIsCheckout() {
    const { urlHelper } = this.utils

    const isCheckout = urlHelper.searchParams.get('isCheckout')
    return isCheckout === 'Y'
  }

  get isLogin() {
    return !!this.context.customer
  }

  get quoteStatus() {
    const { status } = this.state
    return this.statusMap[status] ?? ''
  }

  get shouldShowSubmitBtn() {
    const { status } = this.state

    // Junior buyer cant access to checkout
    const {
      B3RoleId,
    } = this.utils.B3Storage
    const {
      constants: {
        B3Role: {
          JUNIOR,
        },
      },
    } = this.utils
    if (B3RoleId.value && B3RoleId.value === JUNIOR) {
      return false
    }

    return +status === 1
  }

  get shouldShowSendMessageBtn() {
    const { status } = this.state

    return +status === 1
  }

  get isAdditionalInfoShow() {
    const { legalTerms, notes } = this.state
    return !!legalTerms || !!notes
  }

  get hasTrackingHistory() {
    const { trackingHistory } = this.state
    return trackingHistory.length > 0
  }

  async init() {
    if (!/^\d{10}$/.test(this.quoteDate)) {
      this.utils.Alert.error(this.text['rfq.error.formNotExist'])
      return
    }
    const elBody = document.querySelector('body')
    if (elBody) {
      document.querySelector('.body').style.display = 'block'
    }

    if (this.isB2CUser) {
      await this.utils.getAndStoreBCToken()
    }
    await this.getRfqDetail()
    this.initTableData()
    this.render()
    this.renderTotal()
    this.renderMessage()
    this.submitBtn()
    this.initCopyQuoteLinkClipboard()
    this.getRfqStoreInfo()
    this.initMobileTable()

    if (this.quoteIsCheckout) {
      this.getStoresProductSetting()
    }
  }

  async getStoresProductSetting() {
    window.B3Spinner.show()
    try {
      const { store_hash: storeHash } = this.context.settings
      const { location, jsContext } = window

      const { channelId } = jsContext
      const { hidePriceFromGuests } = await this.api.getStoresProductSetting(storeHash, channelId)
      sessionStorage.setItem('QuoteCheckoutUrl', JSON.stringify(location.href))
      this.setState({ hidePriceFromGuests })

      this.quoteCheckoutAction()
    } catch (e) {
      //
    } finally {
       window.B3Spinner.hide()
    }
  }

  quoteCheckoutAction() {
    const QuoteCheckoutUrl = sessionStorage.getItem('QuoteCheckoutUrl')
    const { hidePriceFromGuests } = this.state
    if (!QuoteCheckoutUrl) return

    if (hidePriceFromGuests && !this.isLogin) {
      location.href = '/login.php'
    } else {
      const $toCheckoutBtn = document.querySelector(
        '.rfq-checkout-btn-wrap input',
      )

      if ($toCheckoutBtn && QuoteCheckoutUrl) {
        $toCheckoutBtn.click()
      }
    }
  }

  async getRfqStoreInfo() {
    const { store_hash: storeHash } = this.context.settings
    const { channelId } = window.jsContext
    const data = {
      storeHash,
    }
    const getInfo = this.isB2BUser ? this.api.getRfqStoreInfo : this.api.getStoreBasicInfo
    if (!this.isB2BUser) {
      data.bcChannelId = channelId
    }

    const { storeUserInfo: { quoteLogo } } = await getInfo(data)
    this.setState({ quoteLogo })
  }

  async getRfqDetail() {
    const { store_hash: storeHash } = this.context.settings

    window.B3Spinner.show()
    try {
      const { list: extraFieldConfigList } = await this.api.getStorefrontExtraFields({ storeHash }, 'quote')

      const resp = await this.api.getRfqDetail(this.quoteId, {
        isBackendUser: 0,
        storeHash,
        date: this.quoteDate,
      })

      const { backendAttachFiles, storefrontAttachFiles, extraFields } = resp

      const extraFieldLists = []
      if (extraFieldConfigList.length) {
        extraFieldConfigList.forEach(item => {
          if (item.visibleToEnduser) {
            const currentExtraField = extraFields.find(extraField => extraField.fieldName === item.fieldName)
            extraFieldLists.push({
              fieldName: item.fieldName,
              fieldValue: currentExtraField?.fieldValue || item.defaultValue,
            })
          }
        })
      }

      this.setState({
        ...resp,
        backendAttachFiles: this.handleJudgeFileType(backendAttachFiles),
        storefrontAttachFiles: this.handleJudgeFileType(storefrontAttachFiles),
        expiredAt: resp.expiredAt * 1000,
        createdAt: resp.createdAt * 1000,
        quoteExtraFields: extraFieldLists || [],
      })
    } finally {
      this.utils.B3Storage.BCToken.setValue('')
      window.B3Spinner.hide()
    }
  }

  render() {
    const {
      quote: { container },
    } = this.doms
    const {
      quoteStatus,
      shouldShowSubmitBtn,
      shouldShowSendMessageBtn,
      isAdditionalInfoShow,
      hasTrackingHistory,
      tpls: {
        quoteDetail,
        sendMessage,
        attachFile,
        additionalInfo,
        header,
      },
    } = this
    const { storefrontAttachFiles, backendAttachFiles } = this.state
    const isShowAttachment = !(isEmpty(storefrontAttachFiles) && isEmpty(backendAttachFiles))

    this.utils.renderTemplate({
      hbsTemplate: header,
      containerSelector: container,
      templateConfig: {
        ...this.state,
        quoteStatus,
        showCopyBtn: true,
        showStatusBtn: true,
        clipboardTextUrl: location.href,
      },
      insertType: 'beforeend',
    })
    this.utils.renderTemplate({
      hbsTemplate: quoteDetail,
      containerSelector: container,
      templateConfig: {
        ...this.state,
      },
      insertType: 'beforeend',
    })
    this.utils.renderTemplate({
      hbsTemplate: additionalInfo,
      containerSelector: container,
      templateConfig: {
        ...this.state,
        isAdditionalInfoShow,
      },
      insertType: 'beforeend',
    })
    this.utils.renderTemplate({
      hbsTemplate: header,
      containerSelector: container,
      templateConfig: {
        ...this.state,
        quoteStatus,
      },
      insertType: 'beforeend',
    })
    if (shouldShowSendMessageBtn || hasTrackingHistory) {
      this.utils.renderTemplate({
        hbsTemplate: sendMessage,
        containerSelector: container,
        templateConfig: {
          ...this.state,
          shouldShowSendMessageBtn,
        },
        insertType: 'beforeend',
      })
    }
    this.utils.renderTemplate({
      hbsTemplate: attachFile,
      containerSelector: container,
      templateConfig: {
        ...this.state,
        shouldShowSubmitBtn,
        isShowAttachment,
      },
      insertType: 'beforeend',
    })
    document.querySelector('.store-address').innerHTML = this.state.storeInfo.storeAddress.replace(/[\r\n↵]/g, '<br/>')
  }

  handleJudgeFileType(fileList) {
    if (fileList.length > 0) {
      fileList.map(item => {
        item.isImage = !!item.fileType.includes('image')
        return item
      })
    }
    return fileList
  }

  initCopyQuoteLinkClipboard() {
    const copyQuoteLinkClipboard = new Clipboard('.copy_quote_link')
    copyQuoteLinkClipboard.on('success', () => {
      const tipsEle = document.querySelector('.rfq-copied-tips')
      if (tipsEle.style.display === 'block') return
      tipsEle.style.display = 'block'
      setTimeout(() => tipsEle.style.display = 'none', 1000)
    })
    copyQuoteLinkClipboard.on('error', error => {
      this.utils.Alert.error(error.message)
    })
  }

  printPDF() {
    const styleContent = `
      @page {
        size: auto;  /* auto is the initial value */
        margin: 0mm; /* this affects the margin in the printer settings */
      }
      @media (min-width: 801px) {
        .body {
          margin-top: 0;
        }
      }
      .body {
        margin-top: 0;
      }
      body > :not(.body) {
        display: none;
      }
      .body > :not(.container) {
        display: none;
      }
      .account > :not(.b2b-wrap) {
        display: none;
      }
      .b2b-wrap > :not(.quote-detail) {
        display: none;
      }
      .page-heading,.breadcrumbs{
        display:none;
      }
      .navBar--account {
        display: none;
      }
      nav {
        display: none;
      }
      main .page > :not(.rfq-container-info-wrap) {
        display: none;
      }
    `

    const style = document.createElement('style')
    style.media = 'print'
    style.innerHTML = styleContent
    document.head.appendChild(style)
    window.print()
  }

  exportPDF = async () => {
    window.B3Spinner.show()
    try {
      const lang = sessionStorage.getItem('langCode') || 'en'
      const { createdAt } = this.state
      const { url } = await this.api.exportNewRfqPDF(this.quoteId, {
        storeHash: this.context.settings.store_hash,
        lang,
        createdAt: createdAt / 1000,
        isPreview: false,
      })

      window.open(url, '_blank')
    } finally {
      window.B3Spinner.hide()
    }
  };

  initTableData() {
    const { productList, currency } = this.state

    const { currencyFormat } = this.utils
    const currentCurrency = Object.assign(currency, { currencyExchangeRate: 1 })
    productList.forEach((item, index) => {
      const { quantity, basePrice, offeredPrice } = item

      const subTotal = quantity * basePrice
      const offeredSubtotal = quantity * offeredPrice
      const product = item.imageUrl ? { image: { data: item.imageUrl } } : {}
      const { image } = this.utils.getCorrectProductImage(product)
      item.image = image
      item.idx = index < 9 ? `0${index + 1}` : index
      item.displayBasePrice = currencyFormat(basePrice, true, false, currentCurrency)
      item.displayOfferedPrice = currencyFormat(offeredPrice, true, false, currentCurrency)
      item.displaySubTotal = currencyFormat(subTotal, true, false, currentCurrency)
      item.displayOfferedSubtotal = currencyFormat(offeredSubtotal, true, false, currentCurrency)
    })
    this.setState({
      productList,
    })
  }

  renderTotal() {
    const {
      currency,
      subtotal,
      discount,
      grandTotal,
      shippingTotal,
      taxTotal,
      totalAmount,
      shippingMethod,
      salesRepInfo,
    } = this.state

    // open: 1, ordered: 4, expired: 5
    const { currencyFormat } = this.utils

    const currentCurrency = Object.assign(currency, { currencyExchangeRate: 1 })
    const isSalesDealWith = salesRepInfo?.salesRepEmail
    const isChoiceAtCheckout = !(shippingMethod?.id)

    let shippingText = ''
    let shippingPrice = ''
    let taxText = ''
    let taxPrice = ''
    // storefront create
    if (!isSalesDealWith) {
      shippingText = this.utils.text('rfq.price.shipping.typeFront')
      shippingPrice = this.utils.text('rfq.price.shipping.TBD')
      taxText = this.utils.text('rfq.price.shipping.estimatedTax')
      taxPrice = currencyFormat(taxTotal, true, false, currentCurrency)
    }

    // selected shipping
    if (!isChoiceAtCheckout) {
      shippingText = this.utils.text('rfq.price.shipping', { hash: { ShippingName: shippingMethod?.description } })
      shippingPrice = currencyFormat(shippingTotal, true, false, currentCurrency)
      taxText = this.utils.text('rfq.price.tax')
      taxPrice = currencyFormat(taxTotal, true, false, currentCurrency)
    }

    // APP deal with but no shipping
    if (isSalesDealWith && isChoiceAtCheckout) {
      shippingText = this.utils.text('rfq.price.shipping.type')
      shippingPrice = this.utils.text('rfq.price.shipping.TBD')
      taxText = this.text['rfq.price.tax']
      taxPrice = this.utils.text('rfq.price.shipping.TBD')
    }

    let frage = ''
    frage += `
      <tr>
        <td class="rfq-total-td">${this.text['rfq.orginal.total']}</td>
        <td>${currencyFormat(subtotal, true, false, currentCurrency)}</td>
      </tr>
      <tr>
        <td class="rfq-total-td">${this.text['rfq.discount']}</td>
        <td>${currencyFormat(discount, true, false, currentCurrency)}</td>
      </tr>
      <tr>
        <td class="rfq-total-td">${this.text['rfq.offered.Total']}</td>
        <td>${currencyFormat(grandTotal, true, false, currentCurrency)}</td>
      </tr>
      <tr>
        <td class="rfq-total-td">${shippingText}</td>
        <td>${shippingPrice}</td>
      </tr>
      <tr>
        <td class="rfq-total-td">${taxText}</td>
        <td>${taxPrice}</td>
      </tr>
      <tr>
        <td class="rfq-total-td">${this.text['rfq.price.grandTotal']}</td>
        <td>${currencyFormat(totalAmount, true, false, currentCurrency)}</td>
      </tr>
      `
    document.querySelector('.rfq-total table').innerHTML = frage
  }

  renderMessage() {
    const $rfqMessageInfo = document.querySelector('.rfq-message-info-detail')
    if (!$rfqMessageInfo) return
    const { trackingHistory } = this.state
    let frage = ''
    trackingHistory.forEach(item => {
      frage += `
      <div class="rfq-message-info-item">
        <p>
        ${window.B3ExtendsDisplayFormat(new Date(item.date * 1000))} (${item.role})
        </p>
        <p style="white-space: pre-wrap;">${utils.convertHtmlToText(item.message)}</p>
      </div>
      `
    })
    $rfqMessageInfo.innerHTML = utils.b3FilterXSS(frage)
  }

  submitBtn() {
    const $sendMessageBtn = document.querySelector(
      '.rfq-send-message-btn-wrap input',
    )
    const $toCheckoutBtn = document.querySelector(
      '.rfq-checkout-btn-wrap input',
    )
    const $rfqInput = document.querySelector('.rfq-message-input textarea')
    $sendMessageBtn?.addEventListener('click', async () => {
      const { value } = $rfqInput
      if (!value.trim()) return
      const {
        B3Storage: {
          B3Email: {
            value: userEmail,
          },
        },
      } = this.utils
      const basicInfo = {
        storeHash: this.context.settings.store_hash,
        isBackendUser: 0,
        userEmail,
        message: value,
      }
      window.B3Spinner.show()
      try {
        if (this.isB2CUser) {
          await this.utils.getAndStoreBCToken()
        }
        await this.api.sendRfqMessage(this.quoteId, basicInfo)
        await this.getRfqDetail()
        $rfqInput.value = ''
        this.renderMessage()
      } catch (error) {
        this.utils.Alert.error(error.message)
      }
      window.B3Spinner.hide()
    })

    $toCheckoutBtn?.addEventListener('click', async () => {
      const basicInfo = {
        storeHash: this.context.settings.store_hash,
      }
      let res
      window.B3Spinner.show()
      try {
        if (this.isB2CUser) {
          await this.utils.getAndStoreBCToken()
        }
        res = await this.api.CheckoutRfqQuote(this.quoteId, basicInfo)
      } catch (error) {
        this.utils.Alert.error(error.message)
      } finally {
        this.utils.B3Storage.BCToken.setValue('')
      }
      window.B3Spinner.hide()
      localStorage.setItem('quoteCheckoutId', this.quoteId)
      localStorage.setItem('quoteDate', this.quoteDate)
      sessionStorage.removeItem('QuoteCheckoutUrl')
      location.href = res.checkoutUrl
    })
  }
}
