import BasePage from '../../common/BasePage'
import quotesHtml from './quotes.html'
import listHtml from './list.html'

import { QUOTE_STATUS_MAP, FILTER_FIELDS } from './constants'

export default class Quotes extends BasePage {
  constructor() {
    super()
    this.name = 'Quotes'
    this.state = {
      pagination: {
        offset: 0,
        limit: 10,
        totalCount: 0,
      },
      lists: [],
      sortBy: 'updatedAt',
      orderBy: 'DESC',
      QUOTE_STATUS_MAP,
      FILTER_FIELDS,
    }

    // dom
    this.tpls = {
      quotesHtml,
      listHtml,
    }
  }

  get isInPage() {
    return this.utils.leftIncludes(window.location.pathname, this.doms.quotes.url)
  }

  async init() {
    const $pageSidebar = document.querySelector('.page-sidebar')
    if ($pageSidebar) {
      $pageSidebar.style.display = 'none'
    }
    await this.initCompanyMsq()
    this.state.userInfo = { ...this.utils.B3Storage }

    if (!this.isB2BUser) return

    if (!this.isInPage) return

    this.render()
    this.handleFilterOperation()
    this.initBtn()
    this.hideQuoteActions()
    this.initMobileTable()
  }

  initBtn() {
    const $filterSwitchBtn = document.querySelector('.button-filter')
    const $sortThs = document.querySelectorAll('th[data-sort-th]')
    const $sortThsParent = document.querySelector('.quote-list-table thead tr')

    $sortThs.forEach($sortTh => {
      $sortTh.addEventListener('click', () => {
        const isAsc = $sortTh.classList.contains('asc')
        const sortBy = $sortTh.dataset.sortFilter

        const $asc = $sortThsParent.querySelector('.asc')

        let orderBy

        if (isAsc) {
          $sortTh.classList.remove('asc')
          orderBy = 'DESC'
        } else {
          orderBy = 'ASC'
          if ($asc) $asc.classList.remove('asc')
          $sortTh.classList.add('asc')
        }

        this.setState({
          orderBy,
          sortBy,
        })

        this.renderLists()
      })
    })

    $filterSwitchBtn.addEventListener('click', e => {
      e.stopPropagation()
      this.toggleFilters()
    })
  }

  async initCompanyMsq() {
    const { B3CompanyId } = this.utils.B3Storage

    const beginInCreateQuote = sessionStorage.getItem('beginInCreateQuote') && B3CompanyId?.value

    if (!beginInCreateQuote) return

    await this.handleEndMasqueradeCompany(false)
  }

  toggleDropdown(e) {
    const item = e.target.nextElementSibling

    if (item.classList.contains('show-action')) {
      item.classList.remove('show-action')
    } else {
      const hasShowItem = document.querySelector('.show-action')
      if (hasShowItem) document.querySelector('.show-action').classList.remove('show-action')
      item.classList.add('show-action')
    }
  }

  toggleFilters() {
    const $quoteFilterForm = document.querySelector('.quotes-filter-form')
    const DISPLAY_STATUS = {
      none: 'block',
      block: 'none',
      '': 'block',
    }
    $quoteFilterForm.style.display = DISPLAY_STATUS[$quoteFilterForm.style.display]
  }

  async checkout(e) {
    const { quoteId, quoteDate } = e.target.dataset
    const basicInfo = {
      storeHash: this.context.settings.store_hash,
    }
    let res
    window.B3Spinner.show()
    try {
      if (this.isB2CUser) {
        await this.utils.getAndStoreBCToken()
      }
      res = await this.api.CheckoutRfqQuote(quoteId, basicInfo)
    } catch (error) {
      this.utils.Alert.error(error.message)
    } finally {
      this.utils.B3Storage.BCToken.setValue('')
    }
    window.B3Spinner.hide()
    localStorage.setItem('quoteCheckoutId', quoteId)
    localStorage.setItem('quoteDate', quoteDate)
    location.href = res.checkoutUrl
  }

  async handleDeleteQuote(e) {
    const { quoteId } = e.target.dataset

    window.B3Spinner.show()
    await this.api.deleteQuoteList(quoteId)
    await this.renderLists()
    window.B3Spinner.hide()
  }

  hideQuoteActions() {
    document.querySelector('body').addEventListener('click', e => {
      if (e.target.hasAttribute('quote-list-action')) return
      if (document.querySelector('.show-action')) {
        document.querySelector('.show-action').classList.remove('show-action')
      }
    })
  }

  async render() {
    const {
      quotes: {
        container,
      },
    } = this.doms

    this.utils.renderTemplate({
      hbsTemplate: this.tpls.quotesHtml,
      containerSelector: container,
      templateConfig: {
        ...this.state,
      },
      insertType: 'beforeend',
    })

    await this.renderLists()
  }

  initPagination() {
    const {
      pagination: {
        offset,
        limit,
        totalCount,
      },
    } = this.state

    const currentPage = Math.ceil((offset + 1) / limit)
    const totalPages = Math.ceil(totalCount / limit)
    window.B3Paginator.init({
      container: '#B3pagination',
      currentPage,
      totalPages,
      visiblePages: limit,
      onPageChange: this.handlePaginationChange.bind(this),
    })
  }

  async renderLists() {
    window.B3Spinner.show()
    const { list, pagination } = await this.getQuotes()
    this.state.pagination = pagination
    this.state.lists = list

    this.state.lists = list.map(item => {
      const { QUOTE_STATUS_MAP } = this.state
      const {
        status,
        subtotal,
        createdAt,
        currency,
      } = item
      item.quoteDate = createdAt
      item.status = QUOTE_STATUS_MAP[status];
      // format date
      ['createdAt', 'updatedAt', 'expiredAt'].forEach(dateType => {
        item[dateType] = item[dateType] && item[dateType] * 1000
      })

      // format currency
      const {
        currencyFormat,
        B3Storage: {
          B3RoleId,
        },
        constants: {
          B3Role,
        },
      } = this.utils
      item.subtotal = currencyFormat(parseFloat(subtotal).toFixed(2), true, false, Object.assign(currency, { currencyExchangeRate: 1 }))

      // Junior buyer cant access to checkout
      item.isShowCheckOut = QUOTE_STATUS_MAP[status] === this.text['quotes.list.status.open'] && B3RoleId.value !== B3Role.JUNIOR
      item.isShowViewOrder = QUOTE_STATUS_MAP[status] === this.text['quotes.list.status.ordered'] && B3RoleId.value !== B3Role.JUNIOR
      return item
    })

    document.querySelector('#quote-list-container').innerHTML = ''

    this.utils.renderTemplate({
      hbsTemplate: this.tpls.listHtml,
      containerSelector: '#quote-list-container',
      templateConfig: {
        ...this.state,
        classes: this.classes,
      },
      insertType: 'beforeend',
    })

    this.initPagination()
    window.B3Spinner.hide()
  }

  async handlePaginationChange(page) {
    const { limit } = this.state.pagination
    this.state.pagination.offset = (page - 1) * limit
    await this.renderLists()
  }

  getQuotes() {
    const {
      FILTER_FIELDS,
      sortBy,
      orderBy,
    } = this.state

    const q = document.querySelector('#search-quotes-input').value.trim()
    const status = document.querySelector('#filter-quotes-status-input').value.trim()

    const params = {
      q,
      status,
      sortBy,
      orderBy,
    }

    FILTER_FIELDS.forEach(field => {
      const { value: fieldName, type } = field
      if (fieldName) {
        let fieldValue = document.querySelector(`#filter-quotes-${fieldName}-input`)?.value?.trim()
        if (type === 'date' && fieldValue) {
          fieldValue = this.utils.DateTime.getMonthDayYear(new Date(fieldValue).getTime() / 1000)
        }
        if (fieldValue) {
          params[fieldName] = fieldValue
        }
      }
    })

    return this.api.getQuotes({ ...params, ...this.state.pagination })
  }

  resetPagination() {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
      },
    })
    this.renderLists()
  }

  handleFilterOperation() {
    document.querySelector('#filter_cancel_button').addEventListener('click', () => {
      const {
        FILTER_FIELDS,
      } = this.state

      // reset fields input
      FILTER_FIELDS.forEach(field => {
        const { value: fieldName } = field
        if (fieldName) {
          document.querySelector(`#filter-quotes-${fieldName}-input`).value = ''
        }
      })

      document.querySelector('#filter-quotes-status-input').value = ''

      this.resetPagination()
    })
  }

  // handle Date range validate
  handleDateChange(e) {
    const { dataset: { pairfields } } = e.target

    const pairfieldsArr = pairfields.split('|')

    const $beginField = document.querySelector(`#filter-quotes-${pairfieldsArr[0]}-input`)
    const $endField = document.querySelector(`#filter-quotes-${pairfieldsArr[1]}-input`)

    const $beginFieldVal = $beginField.value
    const $endFieldVal = $endField.value
    if (!$beginFieldVal || !$endFieldVal) return

    const $beginFieldTimestamp = new Date($beginFieldVal).getTime()
    const $endFieldTimestamp = new Date($endFieldVal).getTime()
    const diff = $beginFieldTimestamp - $endFieldTimestamp

    if (diff < 0) return
    $beginField.value = $endFieldVal
    $endField.value = $beginFieldVal
  }
}
